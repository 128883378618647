// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "trix"
import "@rails/actiontext"

require("@popperjs/core")

import * as bootstrap from 'bootstrap'
import "./src/check_ins"

import Swiper from 'swiper/bundle'
import 'swiper/css/bundle'

// Adapt Stimulus for Webpack
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

window.piexif = require("piexifjs")

ActiveStorage.start()
window.bootstrap = bootstrap;

import "colorthief"

// Setup bootstrap toasts for notice/alert
document.addEventListener('turbo:load', function(){
  //window.scrollTo(0, 0);

  //var toastElList = [].slice.call(document.querySelectorAll('.toast'))
  //var toastList = toastElList.map(function(toastEl) {
    //return new bootstrap.Toast(toastEl).show() // No need for options; use the default options
  //})
  let tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  [...tooltipTriggerList].map(tooltipTriggerEl => bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl));
});

