import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper/bundle';

export default class extends Controller {
  static values = { layout: String }

  connect() {
    console.log('Swiper connected');
    this.swiper = new Swiper(this.element, {
      direction: 'horizontal',
      loop: false,
      freeMode: true,
      grabCursor: true,
      breakpoints: this.breakpointConfig(this.layoutValue),
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    });

  }
  breakpointConfig(layout) {
    console.log('layout', layout);
    switch (layout) {
      case 'loggable-card':
        return {
          0: { slidesPerView: 1.5, slidesPerGroup: 1, spaceBetween: 10 },
          768: { slidesPerView: 2.5, slidesPerGroup: 2, spaceBetween: 15 },
          1024: { slidesPerView: 3.5, slidesPerGroup: 2, spaceBetween: 15 }
        }
      case 'stats':
        return {
          0: { slidesPerView: 2.5, slidesPerGroup: 1, spaceBetween: 10 },
          768: { slidesPerView: 3.5, slidesPerGroup: 2, spaceBetween: 15 },
          1024: { slidesPerView: 5.5, slidesPerGroup: 2, spaceBetween: 15 },
          1440: { slidesPerView: 6, slidesPerGroup: 2, spaceBetween: 15 }
        }
      default:
        return {
          0: { slidesPerView: 1.5, slidesPerGroup: 1, spaceBetween: 10 },
          768: { slidesPerView: 2.5, slidesPerGroup: 2, spaceBetween: 15 },
          1024: { slidesPerView: 3.5, slidesPerGroup: 2, spaceBetween: 15 }
        }
    }
  }
}
